<template>
  <v-row>
    <v-col class="text-center">
      <p>Stats</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Stats",
};
</script>
